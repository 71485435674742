import { useEffect, useState } from "react";
import useENV from "./useENV";
import useGlobal from "../stores/use-global";

export default function useApi({ url, options = {}, lazy = false }) {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState();
  const [error, setError] = useState("");
  const {BASE_URL} = useENV();
  const token = useGlobal((state) => state.token);

  const refetch = async (data, URL) => {
    setLoading(true);
    try {
      const reqOptions = {
        method: "GET",
        ...options,

        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          Authorization: token,
          ...options?.headers,
        },
      };
      if (reqOptions?.method?.toLowerCase() !== "get" && data) {
        reqOptions["body"] = JSON.stringify(data);
      }
      const urlToSend = URL != undefined ? URL : url;
      const res = await fetch(BASE_URL + urlToSend, { ...reqOptions });
      const resData = await res?.json();
      setData(resData);

      setError("");
      return resData;
    } catch (e) {
      console.error(e);
      setData();
      setError(e ?? "Something went wrong.");
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    if (!lazy) {
      refetch();
    }
  }, [lazy]);
  return {
    data,
    loading,
    error,
    refetch,
  };
}
