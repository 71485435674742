import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

const { fetch: originalFetch } = window;
window.fetch = async (...args) => {
  let [resource, config] = args;
  const response = await originalFetch(resource, config);
  if (response.status === 401) {
    localStorage.clear();
    window.location.reload();
  }
  return response;
};

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<App />);


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
