import create from "zustand";
import { devtools, persist } from "zustand/middleware";
const useGlobal = create()(
  devtools(
    persist((set, get) => ({
      token: "",
      setToken: (token) => set({ token }),
      minimized: false,
      toggleMinimized: () => {
        set({
          minimized: !get().minimized,
        });
      },
    }),
    {
      name: "admin-global",
    }
  ),{
    name: "admin-global",
  })
);

export default useGlobal;
