import { Badge, minorScale } from "evergreen-ui";
import React from "react";
import TableComponent from "../../components/Table/Table";

export default function ProductTypes() {
  return (
    <>
      <h1>Product Types</h1>
      <TableComponent
        endpoint="products/types"
        schema={[
          {
            key: "name",
            label: "Name",
          },
          {
            key: "tagsTypes",
            label: "Tag Types",
            render: (item) =>
              item.tagsTypes?.flatMap((tt) => (
                <Badge color="neutral" margin={minorScale(1)}>
                  {tt}
                </Badge>
              )),
            options: {
              endpoint: "tags/types?all=true",
              key: "type",
              isMultiple: true,
            },
          },
        ]}
        options={{
          search: [ 'name' ],
        }}
      />
    </>
  );
}
