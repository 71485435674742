import React from "react";
import { Outlet } from "react-router-dom";
import Sidebar from "../components/Sidebar/Sidebar";
import useGlobal from "../stores/use-global";

export default function MainLayout() {
  const minimized = useGlobal((state) => state.minimized);
  return (
    <div style={{ position: "relative", width: "100vw", height: "100vh" }}>
      <Sidebar />
      <div className={`App-page ${minimized ? "minimized" : ""}`}>
        <Outlet />
      </div>
    </div>
  );
}
