import React from "react";
import { Link } from "react-router-dom";
import "./Button.scss";

export default function Button({
  text,
  type,
  link,
  disabled,
  onClick,
  style,
  fromTable,
}) {
  switch (type) {
    case "LINK":
      return (
        <Link style={style} className="button link" to={link}>
          <span>{text}</span>
        </Link>
      );
    case "SUBMIT":
      return disabled ? (
        <button
          className="button submit disabled"
          disabled
          type="submit"
          onClick={onClick}
          style={style}
        >
          <span>{text}</span>
        </button>
      ) : (
        <button
          className="button submit"
          type="submit"
          onClick={onClick}
          style={style}
        >
          <span>{text}</span>
        </button>
      );
    case "BUTTON":
      return disabled ? (
        <button
          className="button submit disabled"
          disabled
          type="button"
          onClick={onClick}
          style={style}
        >
          <span>{text}</span>
        </button>
      ) : (
        <button
          className="button submit"
          type="button"
          onClick={onClick}
          style={style}
        >
          <span>{text}</span>
        </button>
      );
    case "ADD":
      return (
        <>
          {link ? (
            <Link
              className={`button create ${disabled ? "disabled" : ""}`}
              to={disabled ? "#" : link}
            >
              {/* <Add stroke={disabled ? "#75787E" : "#fff"} /> */}
              <span>{text}</span>
            </Link>
          ) : (
            <div className="button create" onClick={onClick}>
              {/* <Add stroke="#fff" /> */}
              <span>{text}</span>
            </div>
          )}
        </>
      );
    case "DELETE":
      return (
        <button className={`button add delete`} type="submit" onClick={onClick}>
          {/* <Delete fill="#E45447" /> */}
          {text && <span>{text}</span>}
        </button>
      );
      case "DELETE BUTTON":
        return (
          <button
          className="button deleteAll"
          type="button"
          onClick={onClick}
          style={style}
        >
          <span>{text}</span>
        </button>
        );
    case "EDIT":
      return (
        <Link className={`button add edit`} type="submit" to={link}>
          {/* <Edit fill="#000" /> */}
          {text && <span>{text}</span>}
        </Link>
      );
    case "FILTER":
      return (
        <div className="button-wrap">
          <button
            className={`button ${fromTable ? "add" : "submit"} filter`}
            onClick={onClick}
          >
            {/* <Filter /> */}
            {/* <Add stroke="#017CF8" /> */}
            {text && <span>{text}</span>}
          </button>
        </div>
      );
    case "LOADER":
      return (
        <div className="button-wrap">
          <div className={`button  spinloader `} onClick={onClick}>
            {text && <span>{text}</span>}
            <span style={{ paddingLeft: "0.5rem" }}>
              {/* <Spinner size={24} /> */}
            </span>
          </div>
        </div>
      );
    default:
      return (
        <button className="button gray" style={style} onClick={onClick}>
          <span>{text}</span>
        </button>
      );
  }
}
