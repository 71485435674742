import React, { useEffect } from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import "./App.scss";
import { CLIENT_ID } from "./constants";
import MainLayout from "./layouts/MainLayout";
import Login from "./pages/login";
import ProductTypes from "./pages/product-types";
import TagTypes from "./pages/tag-types";
import Tags from "./pages/tags";
import useGlobal from "./stores/use-global";
import Demo from "./pages/demo";
import Dashboard from "./pages/dashboard/Dashboard";
import { GoogleOAuthProvider } from '@react-oauth/google';

function App() {
  const token = useGlobal((state) => state.token);
  return (
      <GoogleOAuthProvider clientId={CLIENT_ID}>
        <BrowserRouter>
        <Routes>
          {token ? (
            <>
              <Route path="/" element={<MainLayout />}>
                <Route path="/product-types" element={<ProductTypes />} />
                <Route path="/tags" element={<Tags />} />
                <Route path="/tag-types" element={<TagTypes />} />
                <Route path="/dashboard" element={<Dashboard />} />
                <Route path="/demo" element={<Demo />} />
                <Route
                  path="/"
                  element={<Navigate to="/product-types" replace />}
                />
              </Route>
              <Route path="*" element={<Navigate to="/" replace />} />
            </>
          ) : (
            <>
              <Route path="/login" element={<Login />} />
              <Route path="*" element={<Navigate to="/login" replace />} />
            </>
          )}
        </Routes>
      </BrowserRouter>
    </GoogleOAuthProvider>
  );
}

export default App;
