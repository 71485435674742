import {
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { IconButton, Pagination, SearchInput, toaster } from "evergreen-ui";
import React, { useEffect, useState } from "react";
import { ReactComponent as Delete } from "../../assets/icons/delete.svg";
import { ReactComponent as Edit } from "../../assets/icons/edit.svg";
import useApi from "../../hooks/useApi";
import Button from "../Button/Button";
import TableDialog from "../Dialog/TableDialog";
import LoadingPage from "../LoadingPage/LoadingPage";
import "./Table.scss";


const TableComponent = ({
  schema = [],
  endpoint = "",
  hooks = { create: (item) => item },
  options = {
    searchable: false,
  },
  onSelectAll,
  selectContent,
}) => {
  const [page, setPage] = useState(1);
  const [query, setQuery] = useState(new Map());
  const [url, setUrl] = useState(endpoint);
  const { data, refetch, loading } = useApi({
    url: endpoint + `?page=${page - 1}}`,
    lazy: true,
  });
  const { refetch: createItem } = useApi({
    url: endpoint,
    options: {
      method: "POST",
    },
    lazy: true,
  });
  const { refetch: saveItem } = useApi({
    url: endpoint,
    options: {
      method: "PUT",
    },
    lazy: true,
  });
  const { refetch: deleteItem } = useApi({
    url: endpoint,
    options: {
      method: "DELETE",
    },
    lazy: true,
  });
  const [shown, setShown] = useState(false);
  const [selectedItem, setSelectedItem] = useState();
  const [loader,setLoader]=useState(false);


 

  useEffect(() => {
    setURL();
  }, [page, query]);

  const setURL = () => {
    let q = "";
    for (const key of query.keys()) {
      q += "&" + key + "=" + query.get(key);
    }
    console.log(`${endpoint}?page=${page - 1}${q}`);
    setUrl(`${endpoint}?page=${page - 1}${q}`);
  };

  useEffect(() => {
    refetch(undefined, url);
  }, [url]);

  const handleClose = () => {
    setSelectedItem(undefined);
    setShown(false);
  };

  const handleRevokeAccess = () => {
    setLoader(true)
    const promises = [];

    selectContent.forEach((brandID) => {
      promises.push(fetch("/admin/access?brandID=" + brandID));
    });

    Promise.all(promises).then((results) => {
      console.log("results",results);
      setLoader(false)
      onSelectAll([])
    }).catch(()=>{
      setLoader(false)
      onSelectAll([])
    
    });
  };

  return (
    <>
      <div className="table">
        {endpoint !== "dashboard" && (
          <Button
            type={"BUTTON"}
            text="+ Create new"
            onClick={() => setShown(true)}
          />
        )}
        <TableContainer
          style={{ padding: "0 50px" }}
          className="table-conatiner"
        >
          {options.search?.map((s) => {
            return (
              <SearchInput
                key={s}
                marginRight={10}
                className="search-input"
                onChange={(e) => {
                  setQuery((prev) => prev.set(s, e.target.value));
                }}
                placeholder={`Search by ${s}`}
              />
            );
          })}
          <Button
            type={"BUTTON"}
            text="Search"
            style={{ height: "35px", lineHeight: "10px" }}
            onClick={() => setURL()}
          />
          {endpoint == "dashboard" && (
            <Button
              type={"DELETE BUTTON"}
              text="Revoke Access"
              style={{ height: "35px", lineHeight: "10px", marginLeft: "10px" }}
              onClick={handleRevokeAccess}
            />
          )}
          {(loading||loader) ? (
            <LoadingPage />
          ) : (
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  {schema.flatMap((item) => (
                    <TableCell
                      style={{ borderBottom: "none" }}
                      className="table-header"
                    >
                      {item.label}
                      {item.label === "" && endpoint === "dashboard" && (
                        <input
                          type="checkbox"
                          checked={
                         data?.content.length!==0 &&   data?.content.length === selectContent.length
                          }
                          onChange={(e) => {
                            if (data?.content.length === selectContent.length) {
                              onSelectAll([]);
                            } else {
                              onSelectAll(data?.content.map((item) => item.id));
                            }
                          }}
                        ></input>
                      )}
                    </TableCell>
                  ))}
                  <TableCell
                    style={{ borderBottom: "none" }}
                    className="table-header"
                  >
                    Actions
                  </TableCell>
                </TableRow>
              </TableHead>
              {data?.content?.flatMap((item, i) => (
                <TableRow>
                  {schema.flatMap((sItem) => (
                    <TableCell>
                      {sItem.render ? sItem.render(item) : item[sItem.key]}
                    </TableCell>
                  ))}
                  <TableCell>
                    <IconButton
                      appearance="minimal"
                      onClick={() => {
                        setSelectedItem(item);
                        setShown(true);
                      }}
                      icon={<Edit fill="#027cf8" />}
                    />
                    <IconButton
                      appearance="minimal"
                      icon={<Delete fill="red" />}
                      onClick={async () => {
                        await deleteItem(undefined, `${endpoint}/${item.id}`);
                        toaster.danger("Item deleted successfully");
                        refetch(undefined, url);
                        handleClose();
                      }}
                    />
                  </TableCell>
                </TableRow>
              ))}
            </Table>
          )}
        </TableContainer>
        <Pagination
          page={page}
          totalPages={data?.totalPages - 1 ?? 1}
          onNextPage={() => setPage(page + 1)}
          onPreviousPage={() => setPage(page - 1)}
          onPageChange={(p) => {
            setPage(p);
          }}
          style={{ display: "flex", justifyContent: "center" }}
        ></Pagination>
      </div>
      <TableDialog
        title={selectedItem ? "Edit item" : "Create new item"}
        shown={shown}
        item={selectedItem}
        onClose={handleClose}
        onSave={async (item) => {
          if (selectedItem) {
            await saveItem(item);
            refetch(undefined, url);
          } else {
            await createItem(hooks.create(item));
            refetch(undefined, url);
          }
          toaster.success("Item saved successfully.");
          handleClose();
        }}
        schema={schema}
      />
    </>
  );
};

export default TableComponent;
