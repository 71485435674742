import React from "react";
import Welcome from "../../components/Welcome/Welcome";
import useGlobal from "../../stores/use-global";
import "./style.scss";
import { GoogleLogin } from '@react-oauth/google';

export default function Login() {
  const { setToken } = useGlobal();
  const responseGoogle = (res) => {
    if (res.credential) setToken(res.credential);
    //localStorage.setItem("tokenId", res.tokenId);
    //localStorage.setItem("username", res?.profileObj?.givenName);
  };

  return (
    <div className="login-page">
      <Welcome />
      <div className="login-container">
        <div>
          <h4>Sign in</h4>
          <GoogleLogin
              onSuccess={responseGoogle}

              onError={() => {
                console.log('Login Failed');
              }}

          />
          {/*<GoogleLogin
            clientId={CLIENT_ID}
            buttonText="Log in with Google"
            hostedDomain="skeep.io"
            isSignedIn
            onSuccess={responseGoogle}
            onFailure={responseGoogle}
            cookiePolicy={"single_host_origin"}
          />*/}
        </div>
      </div>
    </div>
  );
}
