import React, {useEffect, useState} from "react";
import "../../styles/global.scss";
import useApi from "../../hooks/useApi";
import "./demo.scss";
import {Button, SelectField, TextInputField, UserIcon} from "evergreen-ui";

export default function Demo() {
  const [demoUserOBJ, setDemoUserOBJ] = useState({});
  const [brandName, setBrandName] = useState("");
  const [ecommerceURL, setEcommerceURL] = useState("");
  const [industry, setIndustry] = useState("SASS");
  const [isLoading, setIsLoading] = useState(false);
  const {refetch: createDempAccount} = useApi({
    url: "demo",
    options: {
      method: "POST",
    },
    lazy: true,
  });

  const createDemoUser = async () => {
    setIsLoading(true);
    createDempAccount({
      name: brandName,
      ecommerceURL: ecommerceURL,
      industry: industry
    }).then(res => {
      setDemoUserOBJ(res);
      setIsLoading(false);
    });
  }

  return (
    <>
      <h1>Demo</h1>
      <div style={{marginLeft: 50, width: 500}}>
        <TextInputField
          label="Brand name"
          onChange={e => setBrandName(e.target.value)}
          value={brandName}
        />

        <TextInputField
          label="Ecommerce URL"
          onChange={e => setEcommerceURL(e.target.value)}
          value={ecommerceURL}
        />
        <SelectField label="Industry" onChange={event => setIndustry(event.target.value)}>
          <option value="SASS" selected>
            SaaS
          </option>
          <option value="ECOMMERCE">Ecommerce</option>
        </SelectField>
        <Button
          marginRight={16}
          appearance="primary"
          isLoading={isLoading}
          onClick={createDemoUser}
          iconBefore={UserIcon}
        >
          Create demo user
        </Button>
        {demoUserOBJ?.username &&
          <div className="demo-card">
            <div className="demo-card-property-container">
              <p className="demo-card-title">Username</p>
              <p className="demo-card-value">{demoUserOBJ?.username}</p>
            </div>
            <div>
              <p className="demo-card-title">Password</p>
              <p className="demo-card-value">{demoUserOBJ?.password}</p>
            </div>
          </div>}
      </div>
    </>
  );
}
