import React, { useEffect, useRef, useState } from "react";
import ChartsEmbedSDK from "@mongodb-js/charts-embed-dom";
import TableComponent from "../../components/Table/Table";
import {
  Badge,
  Button,
  CogIcon,
  TrashIcon,
  FlowReviewIcon,
  PlayIcon,
  Pane,
  Dialog,
  FormField,
  SelectField,
  TextInputField,
} from "evergreen-ui";
import useENV from "../../hooks/useENV";
import useGlobal from "../../stores/use-global";

export default function Dashboard() {
  const { BASE_URL } = useENV();
  const [forceRefetch, setForceRefetch] = useState(null);
  const [customPlan, setCustomPlan] = useState({});
  const [isChecked, setIsChecked] = useState([]);
  const token = useGlobal((state) => state.token);
  const [selectContent, setSelectContent] = useState([])

  useEffect(() => {
    console.log("refetch");
  }, [forceRefetch]);

  const access = async (brandID) => {
    await fetch(BASE_URL + "access?brandID=" + brandID, {
      method: "POST",
      headers: {
        "Content-type": "application/json",
        Accept: "application/json",
        Authorization: token,
      },
    });
    setForceRefetch(Math.random());
  };

  const CustomPlan = (customPlan) => {
    return (
      <div style={{ padding: 0 }}>
        <p>Engagements: {customPlan?.engagements}</p>
        <p>Seats: {customPlan?.seats}</p>
        <p>Price: {customPlan?.price}</p>
      </div>
    );
  };

  const handleCheck = (e) => {
    const { value, checked } = e.target;
    if (checked) {
      setSelectContent([...selectContent, value]);
    } else {
      setSelectContent(selectContent.filter((e) => e !== value));
    }
  };
  

  return (
    <>
      <h1>Dashboard</h1>

      <TableComponent
        options={{
          search: ["name"],
        }}
        endpoint="dashboard"
        onSelectAll={(value)=>setSelectContent(value)}
        selectContent={selectContent}
        schema={[
          {
            key: "",
            label:"",
            render: (item) => {
              return item?.id === "63a5927248905a35aa67d09e" ? null : (
                <input
                  type="checkbox"
                  value={item.id}
                  checked={selectContent.includes(item.id)}
                  onChange={(e) => handleCheck(e)}
                ></input>
              );
            },
            
          },
          {
            key: "created",
            label: "Date",
            render: (item) => item.created.split("T")[0],
          },
          {
            key: "name",
            label: "Name",
            render: (item) => (
              <Badge height={"100%"} backgroundColor={"#e4f1ff"} color={"blue"}>
                {item?.name}
              </Badge>
            ),
          },
          {
            key: "status",
            label: "Status",
            render: (item) => (
              <Badge
                height={"100%"}
                backgroundColor={
                  item?.status === "ACTIVE"
                    ? "green"
                    : item?.status === "NON_ACTIVE"
                    ? "red"
                    : "orange"
                }
                style={{ color: "white" }}
              >
                {item?.status}
              </Badge>
            ),
          },
          {
            key: "email",
            label: "Email",
            render: (item) => (
              <p
                style={{ cursor: "pointer" }}
                onClick={() =>
                  window.open(
                    "https://app-eu1.hubspot.com/contacts/26070853/objects/0-1/views/all/list?query=" +
                      item.email,
                    "_blank"
                  )
                }
              >
                {item.email}
              </p>
            ),
          },
          {
            key: "url",
            label: "Shop",
            render: (item) => (
              <p
                style={{ cursor: "pointer" }}
                onClick={() => window.open(item.url, "_blank")}
              >
                {item.url}
              </p>
            ),
          },
          {
            key: "plan",
            label: "Plan",
            render: (item) => <Badge height={"100%"}>{item?.plan}</Badge>,
          },
          {
            key: "engagements",
            label: "Engagements",
          },
          {
            key: "lastWeekEngagements",
            label: "7 Days Engagements",
          },
          {
            key: "quiz",
            label: "Live Quiz",
          },
          {
            key: "tags",
            label: "Live Tags",
          },
          {
            key: "swiper",
            label: "Live Swiper",
          },
          {
            key: "emails",
            label: "Emails Collected",
          },
          {
            key: "lastWeekEmails",
            label: "7 Days Emails Collected",
          },
          {
            key: "hasAccess",
            label: "Access",
            render: (item) =>
              item?.hasAccess ? (
                <div onClick={() => access(item.id)}>
                  <Button
                    iconBefore={TrashIcon}
                    backgroundColor={"#E45447"}
                    color={"white"}
                  >
                    Remove Access
                  </Button>
                </div>
              ) : (
                <div onClick={() => access(item.id)}>
                  <Button
                    iconBefore={CogIcon}
                    backgroundColor={"#e4f1ff"}
                    color={"#017cf8"}
                  >
                    Obtain Access
                  </Button>
                </div>
              ),
          },
          {
            key: "customPlan",
            label: "Custom Plan",
            render: (item) =>
              item?.customPlan ? (
                CustomPlan(item?.customPlan)
              ) : (
                <div onClick={() => setCustomPlan({ brandID: item.id })}>
                  <Button
                    iconBefore={CogIcon}
                    backgroundColor={"#e4f1ff"}
                    color={"#017cf8"}
                  >
                    Create Plan
                  </Button>
                </div>
              ),
          },
          {
            key: "liveURLs",
            label: "Preview",
            render: (item) =>
              item?.liveURLs?.length > 0 && (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "10px",
                  }}
                >
                  {item?.liveURLs?.map((i, idx) => (
                    <Button
                      iconBefore={PlayIcon}
                      backgroundColor={"#e4f1ff"}
                      color={"#017cf8"}
                      key={idx}
                      onClick={() => window.open(i, "_blank")}
                    >
                      Preview
                    </Button>
                  ))}
                </div>
              ),
          },
        ]}
      />
      <Pane>
        <Dialog
          isShown={customPlan?.brandID}
          title={"Create Custom Plan"}
          onCloseComplete={() => setCustomPlan({})}
          confirmLabel="Save"
          onConfirm={async () => {
            await fetch(BASE_URL + "customPlan", {
              method: "POST",
              headers: {
                "Content-type": "application/json",
                Accept: "application/json",
                Authorization: token,
              },
              body: JSON.stringify(customPlan),
            });
            setCustomPlan({});
          }}
        >
          <form>
            <TextInputField
              label={"Engagements"}
              value={customPlan?.engagements}
              onChange={(e) => {
                customPlan.engagements = e.target.value;
                setCustomPlan(customPlan);
              }}
            />
            <TextInputField
              label={"Seats"}
              value={customPlan?.seats}
              onChange={(e) => {
                customPlan.seats = e.target.value;
                setCustomPlan(customPlan);
              }}
            />
            <TextInputField
              label={"Price"}
              value={customPlan?.price}
              onChange={(e) => {
                customPlan.price = e.target.value;
                setCustomPlan(customPlan);
              }}
            />
            <TextInputField
              label={"Additional Engagement Price"}
              value={customPlan?.additionalEngagementPrice}
              onChange={(e) => {
                customPlan.additionalEngagementPrice = e.target.value;
                setCustomPlan(customPlan);
              }}
            />
            <TextInputField
              label={"Trial Days"}
              value={customPlan?.trialDays}
              onChange={(e) => {
                customPlan.trialDays = e.target.value;
                setCustomPlan(customPlan);
              }}
            />
          </form>
        </Dialog>
      </Pane>
    </>
  );
}
