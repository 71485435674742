import React from "react";
import TableComponent from "../../components/Table/Table";
import "../../styles/global.scss";
export default function TagTypes() {
  return (
    <>
      <h1>Tag Types</h1>
      <TableComponent
        endpoint="tags/types"
        schema={[
            {
                key: "type",
                label: "Type",
            },
          {
            key: "question",
            label: "Question",
          },
          {
            key: "sentence",
            label: "Sentence",
          },

        ]}
        options={{
            search: [ 'type' ]
        }}
      />
    </>
  );
}
