import {
  Dialog,
  FormField,
  Pane,
  SelectField,
  TagInput,
  TextInputField,
} from "evergreen-ui";
import React, { useEffect, useState } from "react";
import useApi from "../../hooks/useApi";

const TableDialog = ({ title, shown, onClose, item, schema, onSave }) => {
  const [state, setState] = useState();
  useEffect(() => {
    setState(item);
  }, [item]);
  console.log(state);
  return (
    <Pane>
      <Dialog
        isShown={shown}
        title={title}
        onCloseComplete={() => onClose()}
        confirmLabel="Save"
        onConfirm={() => {
          onSave(state);
        }}
      >
        <form>
          {schema?.flatMap((field) =>
            field.options ? (
              field.options.isMultiple ? (
                <FormField label={field.label}>
                  <Multiselect
                    setState={setState}
                    values={state?.[field.key] ?? []}
                    itemKey={field.key}
                    options={field.options}
                  />
                </FormField>
              ) : (
                <SelectField
                  label={field.label}
                  value={state?.[field.key] ?? "-1"}
                  onChange={(e) => {
                    setState({
                      ...state,
                      [field.key]: e.target.value,
                    });
                  }}
                  placeholder="Please select"
                >
                  <option value="-1">Select one</option>
                  <FormOption
                    value={state?.[field.key] ?? ""}
                    options={field.options}
                    endpoint={field.options}
                  />
                </SelectField>
              )
            ) : (
              <TextInputField
                label={field.label}
                value={state?.[field.key] ?? ""}
                onChange={(e) => {
                  setState({
                    ...state,
                    [field.key]: e.target.value,
                  });
                }}
              />
            )
          )}
        </form>
      </Dialog>
    </Pane>
  );
};

const FormOption = ({ options, value }) => {
  const { data } = useApi({
    url: options.endpoint,
  });
  return data?.content?.sort(function compare( a, b ) {
    if ( a.type < b.type ){
      return -1;
    }
    if ( a.type > b.type ){
      return 1;
    }
    return 0;
  }).flatMap((d) => (
    <option selected={d[options.key] === value} value={d[options.key]}>
      {d[options.label]}
    </option>
  ));
};

const Multiselect = ({ options, values, setState, itemKey }) => {
  const { data } = useApi({
    url: options.endpoint,
  });
  const autocompleteItems = React.useMemo(() => {
    return (
      data?.content
        .map((a) => a[options.key])
        .filter((i) => !values.includes(i)) ?? []
    );
  }, [data?.content, values]);
  return (
    <TagInput
      width="100%"
      inputProps={{ placeholder: "Search..." }}
      values={values}
      onChange={(v) => {
        console.log(v);
        setState((prev) => ({
          ...prev,
          [itemKey]: v,
        }));
      }}
      autocompleteItems={autocompleteItems}
    />
  );
};

export default TableDialog;
