import {useState} from "react";
import {DEV_ENV_STRING, LOCAL_ENV_STRING, PROD_ENV_STRING} from "../constants";

const useENV = () => {
    const [env, setEnv] = useState(localStorage.getItem("ENV") || DEV_ENV_STRING);
    const BASE_URL = (env === LOCAL_ENV_STRING && "http://localhost:8080/skeep/v1/admin/") || (env === DEV_ENV_STRING && "https://a-dev.skeep.io/skeep/v1/admin/") || (env === PROD_ENV_STRING && "https://a.skeep.io/skeep/v1/admin/");

    const envOptions = [
        {label:"Run locally",value:LOCAL_ENV_STRING},
        {label:"Development",value:DEV_ENV_STRING},
        {label:"Production",value:PROD_ENV_STRING},
    ];

    return {env,setEnv,BASE_URL,envOptions};
}

export default useENV;