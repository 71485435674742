import { Badge } from "evergreen-ui";
import React from "react";
import TableComponent from "../../components/Table/Table";
import "../../styles/global.scss";

export default function Tags() {
  return (
    <>
      <h1>Tags</h1>

      <TableComponent
        endpoint="tags"
        schema={[
          {
            key: "name",
            label: "Name",
            editable: false,
          },
          {
            key: "type",
            label: "Type",
            render: (item) => <Badge color="neutral">{item.type}</Badge>,
            options: { endpoint: "tags/types?all=true", key: "type", label: "type" },
          },
        ]}
        hooks={{
          create: (item) => ({
            ...item,
            language: "EN",
            origin: "SKEEP",
            strategy: "EXACT"
          }),
        }}
        options={{
          search: [ 'name', 'type' ],
        }}
      />
    </>
  );
}
