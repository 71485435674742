import React from "react";
import Select from "react-select";

function CustomSelect({
      defaultMenuIsOpen,
      options,
      defaultValue,
      onChange,
      formSelect,
      editor,
      label,
      placeHolder,
      width,
      name,
      isEditMode = "true",
      value,
      size = "medium",
      onSelectChange,
      top = false,
      disabled,
      textColor,
      id,
      forceStyle = {},
                      }) {
    const customStyles = {
        container: (provided, state) => ({
            ...provided,
            width: "100%",
        }),
        input: (provided, state) => ({
            ...provided,
            color: textColor ? textColor : "#000",
            width: "auto",
            cursor: "pointer",
        }),
        option: (provided, state) => ({
            ...provided,
            background: state.isSelected ? "#017CF8" : "#E4F1FF",
            cursor: "pointer",
            borderRadius: 4,
            color: state.isSelected ? "#FFF" : "#000",
        }),
        menu: (provided, state) => ({
            ...provided,
            border: "none",
            color: "#fff",
            background: "#E4F1FF",
            padding: 8,
            marginTop: editor ? 15 : 10,
            boxShadow: "0px 0px 6px rgba(0, 0, 0, 0.25)",
            borderRadius: 8,
            marginBottom: top ? 15 : 0,
        }),

        control: (provided, state) => ({
            width: "auto",
            color: "#fff",
            backgroundColor: "#fff",
            display: "flex",
            border: formSelect ? "1px solid #DBE1E7" : "none",
            borderRadius: formSelect ? 8 : 0,
            height: formSelect ? (size === "mini" ? 32 : 48) : "auto",
            outline: state.isFocused && formSelect ? "1px solid #017CF8" : "none",
        }),
        dropdownIndicator: (provided, state) => ({
            ...provided,
            padding: size === "mini" ? "0 3px 0 0" : "8px",
        }),
        indicatorSeparator: (styles) => ({ display: "none" }),

        singleValue: (provided, state) => {
            const opacity = state.isDisabled ? 0.8 : 1;
            const transition = "opacity 300ms";

            return { ...provided, opacity, transition };
        },
    };
    if (formSelect) {
        return (
            <label
                className={`field field-select-container field-${size}`}
                style={{ width: width, ...forceStyle }}
            >
                {label && <span className="field-label">{label}</span>}
                {isEditMode && (
                    <Select
                        isSearchable={false}
                        options={options}
                        styles={customStyles}
                        value={value?.value ? value : null}
                        onChange={(option) => {
                            onChange(option, name, id);
                        }}
                        placeholder={placeHolder}
                        defaultValue={defaultValue}
                        isDisabled={disabled}
                    />
                )}
                {!isEditMode && <span className="field-value">{value}</span>}
            </label>
        );
    }

    return (
        <>
            {label && <span className="select-label">{label}</span>}
            <Select
                defaultMenuIsOpen={defaultMenuIsOpen || false}
                isSearchable={false}
                options={options}
                value={value}
                styles={customStyles}
                maxMenuHeight={200}
                menuPlacement={top ? "top" : "bottom"}
                menuShouldScrollIntoView={true}
                onChange={(option) => onSelectChange(option)}
                defaultValue={defaultValue}
                isDisabled={disabled}
            />
        </>
    );
}
export default CustomSelect;
