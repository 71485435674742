import React from "react";
import { GoogleLogout } from "react-google-login";
import { FaDoorOpen, FaMapSigns, FaShoppingBag, FaTags ,FaUserAlt} from "react-icons/fa";
import { NavLink } from "react-router-dom";
import { ReactComponent as Logo } from "../../assets/icons/logo.svg";
import { ReactComponent as LogoMini } from "../../assets/icons/logoMini.svg";
import { ReactComponent as SideBarArrow } from "../../assets/icons/sideBarArrow.svg";
import {CLIENT_ID, DEV_ENV_STRING, PROD_ENV_STRING} from "../../constants";
import useGlobal from "../../stores/use-global";
import "./Sidebar.scss";
import useENV from "../../hooks/useENV";
import CustomSelect from "../forms/CustomSelect";
export default function Sidebar() {
  const { minimized, toggleMinimized } = useGlobal();
    const {env,setEnv,envOptions} = useENV();
    const handleENVChange = (option) => {
        const {value} = option;
        localStorage.setItem("ENV",value);
        setEnv(value);
        //location.reload();
    };

  return (
    <div className={`sidebar ${minimized ? "minimized" : ""}`}>
      <div className="sidebar-minimize" onClick={toggleMinimized}>
        <SideBarArrow />
      </div>

      <div className="sidebar-container">
        <div className="sidebar-logo">
          <NavLink to="/">
            {!minimized && <Logo />}
            {minimized && <LogoMini />}
          </NavLink>
        </div>

        {!minimized && (
          <h3 className="sidebar-blessing">
            Great to see you {localStorage.getItem("username")}!
          </h3>
        )}

        <div
          className="sidebar-tabs-container"
          style={{ maxHeight: minimized ? "70vh" : "55vh" }}
        >
          <div className="sidebar-options">
            <NavLink to="/tag-types" className="sidebar-option">
              <FaMapSigns color="black" />
              {!minimized && <span>Tag Types</span>}
            </NavLink>
            <NavLink to="/product-types" className="sidebar-option">
              <FaShoppingBag color={"black"} />
              {!minimized && <span>Product Types</span>}
            </NavLink>
            <NavLink to="/tags" className="sidebar-option">
              <FaTags color={"black"} />
              {!minimized && <span>Tags</span>}
            </NavLink>
            <NavLink to="/dashboard" className="sidebar-option">
                  <FaTags color={"black"} />
                  {!minimized && <span>Dashboard</span>}
            </NavLink>
              {env === DEV_ENV_STRING &&
                  <NavLink to="/demo" className="sidebar-option">
                  <FaUserAlt color={"black"} />
                  {!minimized && <span>Demo</span>}
              </NavLink>}
          </div>
        </div>
        <div
          className="sidebar-options logout"
          style={{
            position: "absolute",
            bottom: "0",
            backgroundColor: "#E4F1FF",
            width: "100%",
            cursor: "pointer",
          }}
        >
          <GoogleLogout
            clientId={CLIENT_ID}
            buttonText="Logout"
            render={(renderProps) => (
              <div
                onClick={renderProps.onClick}
                disabled={renderProps.disabled}
                className="sidebar-option"
              >
                <FaDoorOpen color="black" />
                {!minimized && <span>Logout</span>}
              </div>
            )}
            onLogoutSuccess={() => (window.location.href = "/")}
          ></GoogleLogout>
        </div>
      </div>
        <div style={{padding: "0 20px",marginBottom:200}}>
            <p style={{fontWeight:600}}>Choose ENV</p>
            {!minimized &&
                <CustomSelect
                    options={envOptions}
                    value={envOptions.find(obj => obj.value === env)}
                    onSelectChange={handleENVChange}
                />}
        </div>
    </div>
  );
}
