import React from "react";
import Logo from "../../assets/images/logo.webp";

import "./Welcome.scss";

function Welcome() {
  const title = "Welcome to Skeep";
  const subTitle = "guided shopping experiences to maximize eCommerce conversion";

  return (
    <div
      className={"auth-welcome auth-welcome-50-width"}
    >
      <img src={Logo} width={"136px"} /> 
      <div className="auth-welcome-wrapper">
        <div className="auth-welcome-content">
          <h1 className="auth-welcome-title">{title}</h1>
          <div
            className="auth-welcome-desc"
            // dangerouslySetInnerHTML={{ __html: subTitle }}
          > <span>Automated </span>{subTitle}</div>
        </div>
        <div className="auth-welcome-content-small">
          <div className="auth-welcome-company">© Skeep Inc. 2022</div>
        </div>
      </div>
    </div>
  );
}

export default Welcome;
